import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AdminModule from "@/store/module/admin/AdminModule";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import { routePath } from "@/router/router-constants";
import GroupDialog from "../dialogs/Group/Group.vue";
let GroupCard = class GroupCard extends Vue {
    constructor() {
        super(...arguments);
        this.update = {
            dialog: false,
        };
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get canEdit() {
        if (this.isSuperAdmin)
            return true;
        if (this.isAdmin && this.group.creatorId && this.group.creatorId === this.admin.id)
            return true;
        if (this.isAdmin && this.admin.userId && this.group.executiveId === this.admin.userId)
            return true;
        return false;
    }
    get groupAvatar() {
        return `${IMAGE_SERVER_URL}/public/${this.group.logo}`;
    }
    get profile() {
        return {
            main: [
                { title: "Полное название", value: this.group.fullName },
                { title: "Короткое название", value: this.group.shortName },
                { title: "Регион", value: this.group?.contacts?.address?.region.name },
                { title: "Город", value: this.group?.contacts?.address?.city?.name },
                { title: "Адрес", value: this.group?.contacts?.addressText },
            ],
            additional: [
                {
                    title: "Руководитель",
                    value: this.group.executive === null
                        ? "Не указан"
                        : `${this.group.executive.name.substring(0, 1)}. ${this.group.executive.patronymic.substring(0, 1)}. ${this.group.executive.surname}`,
                    isTree: false,
                },
                { title: "Телефон", value: this.group.contacts?.phone, isTree: false },
                { title: "Email", value: this.group.contacts?.email, isTree: false },
            ],
        };
    }
    goToGroupPage() {
        this.$router.push(routePath.admin.GROUP_FACTORY(this.group.id));
    }
};
__decorate([
    Prop({ type: Object })
], GroupCard.prototype, "group", void 0);
GroupCard = __decorate([
    Component({
        components: {
            GroupDialog,
        },
    })
], GroupCard);
export default GroupCard;
