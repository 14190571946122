import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import GroupModule from "@/store/module/shared/GroupModule";
import AdminModule from "@/store/module/admin/AdminModule";
import GroupCard from "./GroupCard.vue";
let GroupsTable = class GroupsTable extends Vue {
    constructor() {
        super(...arguments);
        this.selected = [];
        this.search = "";
        this.loading = true;
        this.page = 1;
        this.itemsPerPage = 25;
        this.pagesCount = 0;
        this.headers = [
            {
                text: "Полное название",
                sortable: true,
                value: "fullName",
            },
            {
                text: "Короткое название",
                sortable: true,
                value: "shortName",
            },
            {
                text: "Код",
                sortable: true,
                value: "code",
            },
            { text: "", value: "data-table-expand" },
        ];
    }
    onSelect() {
        this.$emit("on-select", this.selected);
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get allGroups() {
        return this.$store.getters[GroupModule.types.getters.GROUPS];
    }
    get groups() {
        return this.allGroups.filter((item) => (this.onlyPremoderated ? item.isPremoderated : !item.isPremoderated));
    }
    async mounted() {
        await this.$store.dispatch(AdminModule.types.actions.WAIT_FOR_ADMIN_LOADING);
        const usersQuery = {};
        if (this.admin !== null && !this.admin.isSuperAuth) {
            usersQuery.regionId = this.admin.regionId;
        }
        await this.$store.dispatch(GroupModule.types.actions.GET_GROUPS, usersQuery);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], GroupsTable.prototype, "onlyPremoderated", void 0);
__decorate([
    Watch("selected")
], GroupsTable.prototype, "onSelect", null);
GroupsTable = __decorate([
    Component({
        components: {
            GroupCard,
        },
    })
], GroupsTable);
export default GroupsTable;
