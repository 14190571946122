import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import GroupModule from "@/store/module/shared/GroupModule";
import AdminModule from "@/store/module/admin/AdminModule";
import GroupsTable from "@/components/admin/groups/GroupsTable.vue";
import HeaderActions from "@/components/admin/groups/HeaderActions.vue";
let Groups = class Groups extends Vue {
    constructor() {
        super(...arguments);
        this.selectedGroups = [];
        this.clearSelected = false;
        this.showOnlyPremoderated = false;
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get allGroups() {
        return this.$store.getters[GroupModule.types.getters.GROUPS];
    }
    get groups() {
        return this.allGroups.filter((g) => (this.showOnlyPremoderated ? g.isPremoderated : !g.isPremoderated));
    }
    setSelectedGroups(groups) {
        this.selectedGroups = groups;
    }
    async removeGroups() {
        for (let group of this.selectedGroups) {
            await this.$store.dispatch(GroupModule.types.actions.DELETE_GROUP, { id: group.id });
        }
        this.selectedGroups.splice(0);
        this.clearSelected = !this.clearSelected;
        this.$notify({
            type: "success",
            title: "Выбранные сообщества успешно удалены",
        });
    }
    async depremoderate() {
        for (let group of this.selectedGroups) {
            await this.$store.dispatch(GroupModule.types.actions.UPDATE_GROUP, {
                id: group.id,
                group: { isPremoderated: false },
            });
        }
        this.selectedGroups.splice(0);
        this.clearSelected = !this.clearSelected;
        this.$notify({
            type: "success",
            title: "Выбранные сообщества успешно опубликованны",
        });
    }
};
Groups = __decorate([
    Component({
        components: {
            GroupsTable,
            HeaderActions,
        },
    })
], Groups);
export default Groups;
