import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import AdminModule from "@/store/module/admin/AdminModule";
import ApproveDialog from "../../shared/Approve.vue";
import GroupDialog from "../dialogs/Group/Group.vue";
let HeaderActions = class HeaderActions extends Vue {
    constructor() {
        super(...arguments);
        this.onlyPremoderated = false;
        this.approve = {
            delete: false,
            depremoderate: false,
        };
        this.addGroup = {
            dialog: false,
        };
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isAdmin() {
        return !!this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    removeGroups() { }
    get buttons() {
        return [
            {
                text: "Открыть все",
                icon: "mdi-account-group",
                action: () => {
                    this.$emit("show-only-premoderation");
                    this.onlyPremoderated = !this.onlyPremoderated;
                },
                css: "text-caption",
                showOnlyForSuperAdmin: true,
                show: this.isSuperAdmin && this.onlyPremoderated,
            },
            {
                text: "Открыть премодерацию",
                icon: "mdi-account-group",
                action: () => {
                    this.$emit("show-only-premoderation");
                    this.onlyPremoderated = !this.onlyPremoderated;
                },
                css: "text-caption",
                showOnlyForSuperAdmin: true,
                show: this.isSuperAdmin && !this.onlyPremoderated,
            },
            {
                text: "Добавить сообщество",
                icon: "mdi-tab-plus",
                action: () => {
                    this.addGroup.dialog = true;
                },
                css: "blue",
                showOnlyForSuperAdmin: false,
                show: this.isAdmin,
            },
            {
                text: "Удалить сообщества",
                icon: "mdi-tab-minus",
                action: () => {
                    this.approve.delete = !this.approve.delete;
                },
                css: "red darken-1",
                showOnlyForSuperAdmin: true,
                isHandleSelected: true,
                show: this.isSuperAdmin,
            },
            {
                text: "Одобрить добавление",
                icon: "mdi-plus-box",
                action: () => {
                    this.approve.depremoderate = !this.approve.depremoderate;
                },
                css: "blue darken-1 white--text",
                showOnlyForSuperAdmin: true,
                isHandleSelected: true,
                show: this.isSuperAdmin,
            },
        ];
    }
    depremoderate() {
        this.$emit("on-depremoderate");
    }
    mounted() { }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], HeaderActions.prototype, "selectedGroups", void 0);
__decorate([
    Emit("on-remove")
], HeaderActions.prototype, "removeGroups", null);
HeaderActions = __decorate([
    Component({
        components: {
            ApproveDialog,
            GroupDialog,
        },
    })
], HeaderActions);
export default HeaderActions;
